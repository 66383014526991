import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageProps } from 'gatsby';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { SiteMetadata } from '../types/site-metadata';

type DataProps = {
  site: SiteMetadata;
};

const ContactPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="Contact" />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <h2>Hi ! We’d love to hear from you !</h2>
        </Row>
        <Row className="justify-content-md-center">
          <Form>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="First Name" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Last Name" />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="formGridEmailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="info@ecosystemcooperative.com" />
            </Form.Group>

            <Form.Group controlId="formGridSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Control placeholder="Hiya" />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Form.Row>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Row>
      </Container>
    </Layout >
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
